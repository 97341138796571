import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  FireOutlined,
  PictureOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/user" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "/department",
        sidebar: {
          label: "부서 관리",
        },
        component: loadable(() => import("../pages/user/DepartmentList")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "회원 리스트",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/user/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/enterprise",
    sidebar: {
      icon: <SettingOutlined />,
      label: "기업 정보 설정",
    },
    component: loadable(() => import("../pages/enterprise/Detail")),
  },
  {
    exact: true,
    path: "/template",
    sidebar: {
      icon: <PictureOutlined />,
      label: "템플릿 관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "카테고리 리스트",
        },
        component: loadable(() => import("../pages/template/CategoryList")),
      },
      {
        exact: true,
        visible: false,
        path: "/vcard/:id",
        component: loadable(() => import("../pages/template/Vcard/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/vcard/register/:id",
        component: loadable(() => import("../pages/template/Vcard/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/vcard/detail/:id",
        component: loadable(() => import("../pages/template/Vcard/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/challenge",
    sidebar: {
      icon: <FireOutlined />,
      label: "챌린지 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "챌린지 리스트",
        },
        component: loadable(() => import("../pages/challenge/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/challenge/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/challenge/Detail")),
      },
      {
        exact: true,
        path: "/certify",
        sidebar: {
          label: "승인 요청 챌린지 리스트",
        },
        component: loadable(() => import("../pages/challenge/certify/RequestList")),
      },
      {
        exact: true,
        path: "/certify/refuse",
        sidebar: {
          label: "승인 반려 챌린지 리스트",
        },
        component: loadable(() => import("../pages/challenge/certify/RefuseList")),
      },
      {
        exact: true,
        visible: false,
        path: "/certify/detail/:id",
        component: loadable(() => import("../pages/challenge/certify/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/power",
    sidebar: {
      icon: <BarChartOutlined />,
      label: "파워 매트릭스",
    },
    children: [
      {
        exact: true,
        path: "/vcard",
        sidebar: {
          label: "V-CARD 통계",
        },
        component: loadable(() => import("../pages/power/stats/vcard")),
      },
      {
        exact: true,
        path: "/template",
        sidebar: {
          label: "템플릿 통계",
        },
        component: loadable(() => import("../pages/power/stats/template")),
      },
      {
        exact: true,
        path: "/ranking",
        sidebar: {
          label: "랭킹 통계",
        },
        component: loadable(() => import("../pages/power/stats/user")),
      },
      {
        exact: true,
        path: "/point",
        sidebar: {
          label: "포인트 통계",
        },
        component: loadable(() => import("../pages/power/stats/point")),
      },
    ],
  },
];
